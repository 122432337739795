<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入备注" />
      </a-form-model-item>
      <a-form-model-item label="连接方式:0-全方位NFC 1-OMNIHUB(蓝牙)" prop="type" >
      </a-form-model-item>
      <a-form-model-item label="产品类型：0-一次性 1-电池 2-汽化器" prop="productType" >
      </a-form-model-item>
      <a-form-model-item label="默认为0 自定义尺寸 否则关联{zb_public_config.id}" prop="tankSizeId" >
        <a-input v-model="form.tankSizeId" placeholder="请输入默认为0 自定义尺寸 否则关联{zb_public_config.id}" />
      </a-form-model-item>
      <a-form-model-item label="尺寸值" prop="tankSizeValue" >
        <a-input v-model="form.tankSizeValue" placeholder="请输入尺寸值" />
      </a-form-model-item>
      <a-form-model-item label="电阻值" prop="resistanceValue" >
        <a-input v-model="form.resistanceValue" placeholder="请输入电阻值" />
      </a-form-model-item>
      <a-form-model-item label="最大输出电压" prop="maxOutputVoltage" >
        <a-input v-model="form.maxOutputVoltage" placeholder="请输入最大输出电压" />
      </a-form-model-item>
      <a-form-model-item label="其他选项 否则关联{zb_public_config.id}" prop="otherOptionId" >
        <a-input v-model="form.otherOptionId" placeholder="请输入其他选项 否则关联{zb_public_config.id}" />
      </a-form-model-item>
      <a-form-model-item label="其他选项值" prop="otherOptionValue" >
        <a-input v-model="form.otherOptionValue" placeholder="请输入其他选项值" />
      </a-form-model-item>
      <a-form-model-item label="产品型号/产品编号(唯一)" prop="productModel" >
        <a-input v-model="form.productModel" placeholder="请输入产品型号/产品编号(唯一)" />
      </a-form-model-item>
      <a-form-model-item label="代码(唯一)" prop="variantCode" >
        <a-input v-model="form.variantCode" placeholder="请输入代码(唯一)" />
      </a-form-model-item>
      <a-form-model-item label="产品名/营销名" prop="name" >
        <a-input v-model="form.name" placeholder="请输入产品名/营销名" />
      </a-form-model-item>
      <a-form-model-item label="产品图片(多图)" prop="url" >
        <a-input v-model="form.url" placeholder="请输入产品图片(多图)" />
      </a-form-model-item>
      <a-form-model-item label="状态：1开启 0禁用" prop="status" >
      </a-form-model-item>
      <a-form-model-item label="设置封面" prop="picture" >
        <a-input v-model="form.picture" placeholder="请输入设置封面" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getSku, addSku, updateSku } from '@/api/cigarette/sku'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        createTime: null,

        remark: null,

        type: null,

        productType: null,

        tankSizeId: null,

        tankSizeValue: null,

        resistanceValue: null,

        maxOutputVoltage: null,

        otherOptionId: null,

        otherOptionValue: null,

        productModel: null,

        variantCode: null,

        name: null,

        url: null,

        status: 0,

        picture: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        type: [
          { required: true, message: '连接方式:0-全方位NFC 1-OMNIHUB(蓝牙)不能为空', trigger: 'change' }
        ],
        productType: [
          { required: true, message: '产品类型：0-一次性 1-电池 2-汽化器不能为空', trigger: 'change' }
        ],
        tankSizeValue: [
          { required: true, message: '尺寸值不能为空', trigger: 'blur' }
        ],
        resistanceValue: [
          { required: true, message: '电阻值不能为空', trigger: 'blur' }
        ],
        maxOutputVoltage: [
          { required: true, message: '最大输出电压不能为空', trigger: 'blur' }
        ],
        productModel: [
          { required: true, message: '产品型号/产品编号(唯一)不能为空', trigger: 'blur' }
        ],
        variantCode: [
          { required: true, message: '代码(唯一)不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '产品名/营销名不能为空', trigger: 'blur' }
        ],
        url: [
          { required: true, message: '产品图片(多图)不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        createTime: null,
        remark: null,
        type: null,
        productType: null,
        tankSizeId: null,
        tankSizeValue: null,
        resistanceValue: null,
        maxOutputVoltage: null,
        otherOptionId: null,
        otherOptionValue: null,
        productModel: null,
        variantCode: null,
        name: null,
        url: null,
        status: 0,
        picture: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getSku({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateSku(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addSku(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
