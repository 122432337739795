import request from '@/utils/request'


// 查询新-产品规格信息列表
export function listSku(query) {
  return request({
    url: '/cigarette/brand-sku/list',
    method: 'get',
    params: query
  })
}

export function  listByUserId(query) {
  return request({
    url: '/cigarette/brand-sku/listByUserId',
    method: 'get',
    params: query
  })
}
// 查询新-产品规格信息分页
export function pageSku(query) {
  return request({
    url: '/cigarette/brand-sku/page',
    method: 'get',
    params: query
  })
}

// 查询新-产品规格信息详细
export function getSku(data) {
  return request({
    url: '/cigarette/brand-sku/detail',
    method: 'get',
    params: data
  })
}

export function detailBrandSkuPower(data) {
  return request({
    url: '/cigarette/brand-sku/detailBrandSkuPower',
    method: 'get',
    params: data
  })
}

// 新增新-产品规格信息
export function addSku(data) {
  return request({
    url: '/cigarette/brand-sku/add',
    method: 'post',
    data: data
  })
}

// 修改新-产品规格信息
export function updateSku(data) {
  return request({
    url: '/cigarette/brand-sku/edit',
    method: 'post',
    data: data
  })
}

// 删除新-产品规格信息
export function delSku(data) {
  return request({
    url: '/cigarette/brand-sku/delete',
    method: 'post',
    data: data
  })
}
